export const isValidEmail = email => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const roundNumber = (number, decimals = 2) =>
  +(Math.round((number + Number.EPSILON) * 100) / 100).toFixed(decimals);

export const formatPhoneNumber = input => {
  let numberPattern = /\d+/g; // Regular expression to match numbers
  let plusSign = input.includes('+') ? '+' : ''; // Check if the input contains a plus sign
  let numbers = input.match(numberPattern).join(''); // Join all the number parts into a single string
  return plusSign + numbers; // Concatenate the plus sign and the numbers
};

export const delay = ms => new Promise(res => setTimeout(res, ms));

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const convertTagValueToLabel = tagValue => {
  if (tagValue === 'black-afro') {
    return 'Black/Afro';
  }
  return tagValue
    .split('-')
    .map(tag => titleCase(tag))
    .join(' ');
};

export const splitArrayInHalf = arr => {
  const middleIndex = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);

  return [firstHalf, secondHalf];
};

export const isSubscribed = subscribedTo => {
  if (!subscribedTo) {
    return false;
  }
  const subscribedToDate = new Date(subscribedTo);
  const currentDateTime = new Date();

  return subscribedToDate > currentDateTime;
};

export const capitalizeFirstLetter = string => {
  if (string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const getFormattedDate = date => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '-' + day + '-' + year;
};

export const formatDuration = seconds => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

// permissionsList => ['camera', 'microphone']
export const getMissingPermissions = async permissionsList => {
  try {
    const missingPermissions = [];
    if (navigator.permissions) {
      for (const permission of permissionsList) {
        const permissionStatus = await navigator.permissions.query({ name: permission });
        if (permissionStatus.state !== 'granted') {
          missingPermissions.push(permission);
        }
      }
      return {
        missingPermissions,
        isAndroid: false,
      };
    }

    return {
      missingPermissions: [],
      isAndroid: true,
    };
  } catch (err) {
    return {
      missingPermissions: permissionsList,
      isAndroid: false,
    };
  }
};
