import React from 'react';
import { Container, Button } from './UnauthenticatedPage.styles';
import LogoComponent from '../../../components/LogoComponent/LogoComponent';

const UnauthenticatedPage = () => {
  return (
    <Container>
      <LogoComponent fullLogo id="unauthenticatedPage" />
      <Button title="Visit our website" onClick={() => window.open('https://onlychats.com', '_blank', 'noreferrer')} />
    </Container>
  );
};

export default UnauthenticatedPage;
