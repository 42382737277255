import styled from 'styled-components';
import { Text14Light } from '../../components/utils/texts/text.styles';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.6rem;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Text = styled(Text14Light)`
  line-height: 2.4rem;
`;
