import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Container } from './PrivateLayout.styles';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';

const PrivateLayout = () => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default PrivateLayout;
