import React from 'react';
import ReactModal from 'react-modal';
import {
  ButtonsContainer,
  Content,
  Header,
  ModalStyle,
  OverlayStyle,
  TextContent,
} from './RedirectToWebsiteModal.styles';
import { Text16Regular, Text22Bold } from '../../utils/texts/text.styles';
import { MainButton } from '../../utils/buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createWebsiteUrl } from '../../../store/slices/user/asyncThunks';
import { notifyError } from '../../../utils/notify';
import { selectCreateWebsiteUrlPending } from '../../../store/slices/user/slice';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PremiumIcon } from '../../../assets/icons/premium1.svg';

const RedirectToWebsiteModal = ({ setShow, show, editMode }) => {
  const dispatch = useDispatch();
  const createWebsiteUrlPending = useSelector(selectCreateWebsiteUrlPending);

  const onVisitWebsiteClick = () => {
    dispatch(createWebsiteUrl())
      .unwrap()
      .then(({ websiteUrl }) => {
        setTimeout(() => {
          window.open(websiteUrl, '_blank').focus();
        });
      })
      .catch(err => {
        notifyError(err.message);
      });
  };
  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Content>
            <Header>
              <Text22Bold>{editMode ? 'Edit Profile' : 'Add Credits'}</Text22Bold>
              <CloseIcon onClick={() => setShow(false)} />
            </Header>
            <TextContent>
              {!editMode && <PremiumIcon />}
              <Text16Regular>
                {!editMode
                  ? 'You can manage your subscription and credits on our website.'
                  : 'You can edit your profile on our website. '}
              </Text16Regular>
            </TextContent>
            <ButtonsContainer>
              <MainButton title="Visit Website" onClick={onVisitWebsiteClick} isLoading={createWebsiteUrlPending} />
            </ButtonsContainer>
          </Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default RedirectToWebsiteModal;
