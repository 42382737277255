import { createSlice } from '@reduxjs/toolkit';
import {
  fetchInfluencerByUsernameBuilder,
  fetchInfluencersBuilder,
  fetchInfluencersByTagsBuilder,
  startChatBuilder,
} from './builder';

const INIT_STATE = {
  fetchInfluencersInfo: {
    data: [],
    page: 1,
    maxReached: false,
    pending: false,
  },
  fetchInfluencersByTagsInfo: {
    data: [],
    pending: false,
  },
  selectedInfluencerInfo: {
    pending: false,
    influencer: null,
  },
  pending: {
    startChat: false,
  },
};

const influencerSlice = createSlice({
  name: 'influencer',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchInfluencersBuilder(builder);
    fetchInfluencerByUsernameBuilder(builder);
    fetchInfluencersByTagsBuilder(builder);
    startChatBuilder(builder);
  },
});

export const selectFetchInfluencersInfo = state => state.influencerInfo.fetchInfluencersInfo;
export const selectFetchInfluencersByTagsInfo = state => state.influencerInfo.fetchInfluencersByTagsInfo;
export const selectSelectedInfluencerInfo = state => state.influencerInfo.selectedInfluencerInfo;
export const selectStartChatPending = state => state.influencerInfo.pending.startChat;

export default influencerSlice.reducer;
