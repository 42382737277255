import styled from 'styled-components';
import { MainButton } from '../../../components/utils/buttons/Button/Button';

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 1.6rem;
  margin-top: 1rem;
`;

export const Button = styled(MainButton)`
  margin-top: 2rem;
  padding-inline: 3.6rem;
`;
