import { fetchUserInfo } from '../user/asyncThunks';

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.fulfilled, state => {
    state.loggedIn = true;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.loggedIn = false;
  });
};
