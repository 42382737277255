import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import BaseCallModal from '../BaseCallModal/BaseCallModal';
import { useDispatch } from 'react-redux';
import { initVoiceCall } from '../../../store/slices/voiceCall/asyncThunks';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import PermissionsModal from '../PermissionsModal/PermissionsModal';
import {
  AvatarContainer,
  AvatarImage,
  ButtonContainer,
  ButtonText,
  CoverPhoto,
  CoverPhotoContainer,
  CoverPhotoContainerShadowLayer,
  EnableSoundComp,
  EnableSoundWrapper,
  EndCallButton,
  ProfilePhotoContainer,
} from './VoiceCallModal.styles';
import { Text16Light, Text22Bold } from '../../utils/texts/text.styles';
import { formatDuration, getMissingPermissions } from '../../../utils/util';
import useDailyVoiceCall from '../../../hooks/useDailyVoiceCall';
import { notifyError, notifyInvisible } from '../../../utils/notify';
import { DailyAudioTrack } from '@daily-co/daily-react';

export const CALL_STATUS = {
  INACTIVE: 'INACTIVE',
  LOADING: 'LOADING',
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  CANCEL: 'CANCEL',
};

const VoiceCallModal = forwardRef(({ onClose, influencer, secretMode }, ref) => {
  const [callStatus, setCallStatus] = useState(CALL_STATUS.INACTIVE);
  const [conversationUrl, setConversationUrl] = useState(null);
  const { dailyLocalSessionId, dailyParticipantIds, dailyError, callDuration } = useDailyVoiceCall({
    conversationUrl,
    setCallStatus,
  });
  const [isAndroid, setIsAndroid] = useState(false);
  const [shouldDisplayAndroidConfirmation, setShouldDisplayAndroidConfirmation] = useState(false);
  const baseModalRef = useRef();
  const permissionsModalRef = useRef();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    async startCall() {
      try {
        baseModalRef.current.show();
        const { missingPermissions, isAndroid } = await getMissingPermissions(['microphone']);
        setIsAndroid(isAndroid);
        if (!missingPermissions.length) {
          await startVoiceCall();
        } else {
          permissionsModalRef.current.show(missingPermissions, 'video calls');
        }
      } catch (err) {
        console.log(err);
      }
    },
    hide() {
      baseModalRef.current.hide();
    },
  }));

  const startVoiceCall = async () => {
    try {
      setCallStatus(CALL_STATUS.LOADING);
      dispatch(initVoiceCall({ influencerId: influencer.id, secretModeId: secretMode.id }))
        .unwrap()
        .then(conversationUrl => {
          setConversationUrl(conversationUrl);
        })
        .catch(err => {
          notifyError(err.message);
          setCallStatus(CALL_STATUS.ENDED);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    switch (callStatus) {
      case CALL_STATUS.ACTIVE: {
        if (isAndroid) {
          setShouldDisplayAndroidConfirmation(true);
        }
        break;
      }
      case CALL_STATUS.ENDED: {
        setConversationUrl(null);
        setTimeout(() => {
          setCallStatus(CALL_STATUS.INACTIVE);
          setIsAndroid(false);
          setShouldDisplayAndroidConfirmation(false);
          onClose();
        }, 1200);
        break;
      }
      case CALL_STATUS.CANCEL: {
        setConversationUrl(null);
        setCallStatus(CALL_STATUS.INACTIVE);
        setIsAndroid(false);
        setShouldDisplayAndroidConfirmation(false);
        onClose();
        break;
      }
      default: {
        break;
      }
    }
  }, [callStatus, onClose, isAndroid, dispatch]);

  useEffect(() => {
    if (dailyError) {
      notifyError('Unable to join the call');
      setCallStatus(CALL_STATUS.CANCEL);
    }
  }, [dailyError]);

  const DailyAudioTracks = useMemo(() => {
    if (!dailyLocalSessionId) return null;
    return (
      <>
        {dailyParticipantIds.map(sessionId => (
          <DailyAudioTrack
            key={sessionId}
            sessionId={sessionId}
            onPlayFailed={e => notifyInvisible('Press start')}
            // onPlayFailed={e => notifyError(`Failed to play ${e.type} for ${e.sessionId}. Audio tag: ${e.target}.`)}
          />
        ))}
      </>
    );
  }, [dailyLocalSessionId, dailyParticipantIds]);

  const renderCallStatus = () => {
    switch (callStatus) {
      case CALL_STATUS.INACTIVE:
        return '';
      case CALL_STATUS.LOADING:
        return 'Connecting...';
      case CALL_STATUS.ACTIVE:
        return formatDuration(callDuration);
      case CALL_STATUS.ENDED:
        return 'Call ended';
      default:
        return '';
    }
  };

  return (
    <>
      <BaseCallModal
        ref={baseModalRef}
        onClick={() => {
          if (shouldDisplayAndroidConfirmation) {
            setShouldDisplayAndroidConfirmation(false);
          }
        }}>
        <CoverPhotoContainer>
          <CoverPhoto src={influencer?.albumMedia.photos.cover[0]} />
        </CoverPhotoContainer>
        <CoverPhotoContainerShadowLayer />
        <ProfilePhotoContainer>
          <AvatarContainer>
            <AvatarImage src={influencer?.profilePhoto} />
          </AvatarContainer>
          <Text22Bold>{influencer?.name}</Text22Bold>
          <Text16Light>{renderCallStatus()}</Text16Light>
        </ProfilePhotoContainer>
        {shouldDisplayAndroidConfirmation && callStatus !== CALL_STATUS.ENDED && (
          <EnableSoundWrapper>
            <EnableSoundComp onClick={() => setShouldDisplayAndroidConfirmation(false)} />
          </EnableSoundWrapper>
        )}
        {callStatus !== CALL_STATUS.ENDED && (
          <ButtonContainer>
            <EndCallButton onClick={() => setCallStatus(CALL_STATUS.ENDED)}>
              <CloseIcon color="#fff" />
            </EndCallButton>
            <ButtonText>End</ButtonText>
          </ButtonContainer>
        )}
        {isAndroid && shouldDisplayAndroidConfirmation && dailyParticipantIds.length ? (
          <>
            {dailyParticipantIds.map(sessionId => (
              <DailyAudioTrack
                key={sessionId}
                sessionId={sessionId}
                onPlayFailed={e => notifyInvisible('Press start')}
              />
            ))}
          </>
        ) : (
          DailyAudioTracks
        )}
      </BaseCallModal>
      <PermissionsModal
        ref={permissionsModalRef}
        onSuccess={() => {
          permissionsModalRef.current.hide();
          startVoiceCall();
        }}
        onClose={() => {
          permissionsModalRef.current.hide();
          setCallStatus(CALL_STATUS.CANCEL);
        }}
      />
    </>
  );
});

export default VoiceCallModal;
