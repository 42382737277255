import React from 'react';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import { StyledSkeleton } from './PersonalitiesPageSkeleton.styles';

const PersonalitiesPageSkeleton = () => {
  return Array.from({ length: 6 }).map((_, index) => {
    return (
      <StyledSkeleton key={index} $animationTime={6}>
        <LogoIcon />
      </StyledSkeleton>
    );
  });
};

export default PersonalitiesPageSkeleton;
