import React from 'react';
import { IconWrapper, InputContainer, NameText, ValueContainer } from './SingleFieldInput.styles';
import { Text15Light } from '../../utils/texts/text.styles';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';

const SingleFieldInput = ({ name, value, onEditClick }) => {
  return (
    <InputContainer>
      <NameText>{name}</NameText>
      <ValueContainer>
        <Text15Light>{value}</Text15Light>
        {onEditClick && (
          <IconWrapper onClick={onEditClick}>
            <PencilIcon />
          </IconWrapper>
        )}
      </ValueContainer>
    </InputContainer>
  );
};

export default SingleFieldInput;
