import axiosApiClient from '..';
import { throwApiError, throwErrorIfWrongStatus } from '../util';

const BASE_URL = '/influencers';

class InfluencerService {
  fetchInfluencers = async page => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/mini-app?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers, page };
    } catch (err) {
      throwApiError(err, 'Error occurred while fetching influencers.');
    }
  };

  fetchInfluencerByUsername = async influencerUsername => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/mini-app/${influencerUsername}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throwApiError(err, 'Error occured while fetching influencers.');
    }
  };

  fetchInfluencersByTags = async ({ personalityTags, appearanceTags }) => {
    try {
      const body = {
        personalityTags,
        appearanceTags,
      };
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/category`, body);
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers };
    } catch (err) {
      throw throwApiError(err, 'Error occurred while fetching influencers');
    }
  };

  startChat = async (influencerId, secretModeId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/start-chat`, {
        influencerId,
        secretModeId,
      });
      throwErrorIfWrongStatus(response, 200);
      const { activeInfluencer } = response.data.data;
      return activeInfluencer;
    } catch (err) {
      throwApiError(err, 'Error occured while starting chat');
    }
  };

  buyMoreCredits = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/mini-app/buy-more-credits`);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throwApiError(err, 'Error occured while buying more credits');
    }
  };
}

const influencerService = new InfluencerService();
export default influencerService;
