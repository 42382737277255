import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalStyle, OverlayStyle } from './BaseCallModal.styles';

const BaseCallModal = forwardRef(({ children, onClick }, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    },
  }));

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props} onClick={onClick}>
          {children}
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    />
  );
});

export default BaseCallModal;
