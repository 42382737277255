import React from 'react';
import { CircleTag, Container, TagContainer, TagText } from './PersonalityTags.styles';
import { ReactComponent as SweetIcon } from '../../assets/icons/PersonalityTags/sweet.svg';
import { ReactComponent as DevilIcon } from '../../assets/icons/PersonalityTags/devil.svg';
import { ReactComponent as KissIcon } from '../../assets/icons/PersonalityTags/kiss.svg';
import { ReactComponent as AngelIcon } from '../../assets/icons/PersonalityTags/angel.svg';
import { ReactComponent as DominantIcon } from '../../assets/icons/PersonalityTags/dominant.svg';
import { ReactComponent as CrownIcon } from '../../assets/icons/PersonalityTags/crown.svg';
import { ReactComponent as GameIcon } from '../../assets/icons/PersonalityTags/game.svg';
import { ReactComponent as HandcuffIcon } from '../../assets/icons/PersonalityTags/handcuff.svg';
import { ReactComponent as HearthIcon } from '../../assets/icons/PersonalityTags/hearth.svg';
import { ReactComponent as SnowIcon } from '../../assets/icons/PersonalityTags/snow.svg';
import { ReactComponent as StudyCapIcon } from '../../assets/icons/PersonalityTags/study_cap.svg';
import { ReactComponent as DummyIcon } from '../../assets/icons/PersonalityTags/dummy.svg';
import { ReactComponent as MatureIcon } from '../../assets/icons/PersonalityTags/mature.svg';
import { ReactComponent as DaringIcon } from '../../assets/icons/PersonalityTags/daring.svg';

export const PERSONALITY_TAGS = [
  {
    label: 'Sweet',
    value: 'sweet',
    icon: <SweetIcon />,
  },
  {
    label: 'Wild',
    value: 'wild',
    icon: <DevilIcon />,
  },
  {
    label: 'Lover',
    value: 'lover',
    icon: <KissIcon />,
  },
  {
    label: 'Innocent',
    value: 'innocent',
    icon: <AngelIcon />,
  },
  {
    label: 'Dominant',
    value: 'dominant',
    icon: <DominantIcon />,
  },
  {
    label: 'Bratty',
    value: 'bratty',
    icon: <CrownIcon />,
  },
  {
    label: 'Mature',
    value: 'mature',
    icon: <MatureIcon />,
  },
  {
    label: 'Daring',
    value: 'daring',
    icon: <DaringIcon />,
  },
  {
    label: 'Playful',
    value: 'playful',
    icon: <GameIcon />,
  },
  {
    label: 'Submissive',
    value: 'submissive',
    icon: <HandcuffIcon />,
  },
  {
    label: 'Caring',
    value: 'caring',
    icon: <HearthIcon />,
  },
  {
    label: 'Smartie',
    value: 'smartie',
    icon: <StudyCapIcon />,
  },
  {
    label: 'Dummy',
    value: 'dummy',
    icon: <DummyIcon />,
  },
  {
    label: 'Mean',
    value: 'mean',
    icon: <SnowIcon />,
  },
];

const PersonalityTags = ({ onTagClick, selectedTag }) => {
  return (
    <Container>
      {PERSONALITY_TAGS.map((tag, index) => {
        return (
          <TagContainer
            $last={index === PERSONALITY_TAGS.length - 1}
            $first={index === 0}
            key={tag.value}
            onClick={() => onTagClick && onTagClick(tag.value)}>
            <CircleTag $selected={selectedTag === tag.value}>{tag.icon}</CircleTag>
            <TagText>{tag.label}</TagText>
          </TagContainer>
        );
      })}
    </Container>
  );
};

export default PersonalityTags;
