import styled from 'styled-components';
import { Text16Regular } from '../../texts/text.styles';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 300;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
`;

export const LabelText = styled(Text16Regular)`
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
`;

export const StyledInput = styled.input`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
  color: #49435d;
  background: #fff;
  height: 4.8rem;
  width: 100%;
  border: 1px solid rgba(73, 67, 93, 0.2);
  border-radius: 12px;
  padding: 0.8rem 1.2rem;
  padding-right: ${props => (props.type === 'password' || props.$isShown ? '5rem' : '0')};
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`;

export const StyledTextarea = styled.textarea`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
  color: #49435d;
  background: #fff;
  height: 32rem;
  width: 100%;
  border: 1px solid rgba(73, 67, 93, 0.2);
  border-radius: 12px;
  padding: 0.8rem 1.2rem;
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  resize: none;
`;

export const VisibilityButton = styled.div`
  position: absolute;
  border: none;
  cursor: pointer;
  background: transparent;
  top: ${props => (props.$labelExists ? '4.8rem' : '2.1rem')};
  right: 15px;
  transform: translate(0%, -25%);

  > svg {
    fill: #adaae160;
  }
`;
