import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  z-index: 1;
  margin-bottom: 1rem;
`;

export const BackContainer = styled.div`
  cursor: pointer;
  width: 5rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const EmptyDiv = styled.div`
  width: 5rem;
`;
