import { initVoiceCall } from './asyncThunks';

export const initVoiceCallBuilder = builder => {
  builder.addCase(initVoiceCall.pending, state => {
    state.pending.initVoiceCall = true;
  });
  builder.addCase(initVoiceCall.fulfilled, state => {
    state.pending.initVoiceCall = false;
  });
  builder.addCase(initVoiceCall.rejected, state => {
    state.pending.initVoiceCall = false;
  });
};
