import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
 0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`;

export const Skeleton = styled.div`
  background: linear-gradient(180deg, #100920 0%, #120924 100%);
  border: 1px solid rgba(80, 59, 117, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(0, 0, 0, 0) 100%);
    animation: ${shimmer} ${({ $animationTime }) => `${$animationTime}s`} linear infinite forwards;
  }
`;
