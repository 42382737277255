import styled from 'styled-components';
import { Text14Light } from '../../components/utils/texts/text.styles';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.6rem;
`;

export const AnswerText = styled(Text14Light)`
  color: #b089f6;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
