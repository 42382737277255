import React from 'react';
import ReactModal from 'react-modal';
import { Button, Content, Header, ModalStyle, OverlayStyle, Text } from './TelegramSupportModal.styles';
import { Text22Bold } from '../../utils/texts/text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram1.svg';

const TelegramSupportModal = ({ show, setShow }) => {
  const onEnterClick = () => {
    window.open('https://t.me/onlychatssupport', '_blank');
  };

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text22Bold>Support</Text22Bold>
            <CloseIcon onClick={() => setShow(false)} />
          </Header>
          <Content>
            <TelegramIcon />
            <Text>Our support requests are handled through Telegram.</Text>
          </Content>
          <Button title="Visit Telegram" onClick={onEnterClick} />
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default TelegramSupportModal;
