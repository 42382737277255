import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInfoBuilder } from './builder';

const INIT_STATE = {
  loggedIn: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
  },
});

export const { setLoggedIn } = authSlice.actions;

export const selectLoggedIn = state => state.auth.loggedIn;

export default authSlice.reducer;
