import styled from 'styled-components';
import { Text15Light } from '../../utils/texts/text.styles';

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  border-radius: 24px;
  border: 1px solid #2a1a47;
  background: radial-gradient(78.01% 78.01% at 49.87% 0%, #1f0f3c 0%, #0a0416 100%);
  padding: 2.4rem 3rem;
  max-height: 80%;
  height: 50rem;
  width: 46rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    max-height: unset;
    width: 86%;
    height: 35.2rem;
    border-radius: 0;
    padding: 2rem 2.6rem;
    border-radius: 2rem;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  align-items: center;
  margin-inline: 4rem;
  p {
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.4rem;
  align-items: center;
`;

export const CancelButton = styled(Text15Light)`
  color: #bea1ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;
