import styled from 'styled-components';
export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  background: radial-gradient(78.01% 78.01% at 49.87% 0%, #1f0f3c 0%, #0a0416 100%);
  width: 100%;
  height: 100%;
  padding: 2.4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
`;
