import styled from 'styled-components';
import { Text12ExtraBold } from '../../utils/texts/text.styles';
import { MainButton } from '../../utils/buttons/Button/Button';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

export const YourBalance = styled.div`
  border-radius: 20px;
  border: 1px solid #432181;
  background: radial-gradient(50% 50% at 50% 50%, #1d0f37 0%, #05020b 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  height: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
`;

export const YourBalanceText = styled(Text12ExtraBold)`
  color: #8855e2;
  opacity: 0.45;
  letter-spacing: 5.04px;
`;

export const DiamondText = styled.span`
  margin-left: 0.6rem;
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: calc(50% - 6rem);
  bottom: 2.8rem;
  opacity: 0.5;
`;

export const AddCreditButton = styled(MainButton)`
  width: 14.4rem;
  top: -1rem;
  margin-inline: auto;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 16px;
  height: 4.4rem;
`;
