import React from 'react';
import { Content, EmailText, PageContainer } from './SupportPage.styles';
import Header from '../../components/Header/Header';
import { Text14Regular } from '../../components/utils/texts/text.styles';

const SupportPage = () => {
  return (
    <PageContainer>
      <Header pageId="supportPage" />
      <Content>
        <Text14Regular>To report bugs, get support for as questions, please reach out to us at:</Text14Regular>
        <EmailText>support@onlychats.com 💌</EmailText>
      </Content>
    </PageContainer>
  );
};

export default SupportPage;
