import axios from 'axios';
import { BASE_URL } from '../utils/constants';
const TelegramWebApp = window.Telegram.WebApp;

export const injectStore = _store => {
  // store = _store;
};

const axiosApiClient = axios.create({
  baseURL: `${BASE_URL}/api`,
});

axiosApiClient.interceptors.request.use(
  async config => {
    const token = TelegramWebApp.initData;
    config.headers = {
      ...config.headers,
      Authorization: token,
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export default axiosApiClient;
