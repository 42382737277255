import axiosApiClient from '..';
import { throwApiError, throwErrorIfWrongStatus } from '../util';

const BASE_URL = '/users';

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/mini-app/me`);
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throwApiError(err, "Error occurred while fetching user's info.");
    }
  };

  createWebsiteUrl = async () => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/website-url`);
      throwErrorIfWrongStatus(response, 200);
      const { websiteUrl } = response.data.data;
      return { websiteUrl };
    } catch (err) {
      throwApiError(err, 'Error occurred while fetching website url.');
    }
  };

  setUserPhoneNumber = async phoneNumber => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/phone`, { phoneNumber });
      throwErrorIfWrongStatus(response, 200);
      return phoneNumber;
    } catch (err) {
      throwApiError(err, "Error occured while fetching user's info.");
    }
  };

  changeUserResponseFormat = async ({ responseFormat }) => {
    try {
      const body = { responseFormat };
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/response-format`, body);
      throwErrorIfWrongStatus(response, 200);
      return { responseFormat };
    } catch (err) {
      throw throwApiError(err, 'Error occurred while changing response format.');
    }
  };
}

const userService = new UserService();
export default userService;
