import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../../api/services/user.api';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await userService.fetchUserInfo();
});

export const setUserPhoneNumber = createAsyncThunk('user/setUserPhoneNumber', async phoneNumber => {
  return await userService.setUserPhoneNumber(phoneNumber);
});

export const changeUserResponseFormat = createAsyncThunk('user/changeUserResponseFormat', async requestData => {
  return await userService.changeUserResponseFormat(requestData);
});

export const createWebsiteUrl = createAsyncThunk('user/createWebsiteUrl', async requestData => {
  return await userService.createWebsiteUrl(requestData);
});
