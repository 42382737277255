export const Transition = {
  hover: {
    on: 'all 0.2s ease-in',
    off: 'all 0.2s ease-out',
  },
  reaction: {
    on: 'all 0.15s ease-in',
    off: 'all 0.1s ease-out',
  },
  dropdown: {
    off: 'all 0.35s ease-out',
  },
};

export const theme = {
  colors: {
    mainText: '#fff',
    orange: '#E25E34',
    white: '#fff',
    linearLeft: '#D23402',
    linearRight: '#FE9132',
    error: '#FF2226',
    green: '#00f100',
    black: '#000',
    body: '#050508',
  },
};
