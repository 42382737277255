import React from 'react';
import { BackContainer, EmptyDiv, HeaderContainer } from './Header.styles';
import LogoComponent from '../LogoComponent/LogoComponent';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron_left.svg';
import { useNavigate } from 'react-router-dom';

const Header = ({ onBack, pageId }) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <HeaderContainer>
      <BackContainer onClick={onBackClick}>
        <ChevronLeft />
      </BackContainer>
      <LogoComponent id={pageId} />
      <EmptyDiv />
    </HeaderContainer>
  );
};

export default Header;
