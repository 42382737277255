import { configureStore } from '@reduxjs/toolkit';
import influencerSlice from './slices/influencer/slice';
import userSlice from './slices/user/slice';
import authSlice from './slices/auth/slice';
import voiceCallSlice from './slices/voiceCall/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerInfo: influencerSlice,
    voiceCallInfo: voiceCallSlice,
  },
  devTools: false,
});
