import React from 'react';
import {
  StyledMainButton,
  StyledTransparentButton,
  ButtonIconWrapper,
  ButtonLoader,
  StyledLinearButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  ChildrenContainer,
} from './Button.styles';

const handleLinkWrapping = (Component, props) => {
  const { title, onClick, disabled, isLoading, icon, className, type, children } = props;

  return (
    <Component
      onClick={onClick}
      disabled={disabled || isLoading}
      className={className}
      type={type}
      $isLoading={isLoading}
      $isDisabled={disabled}>
      {icon && !isLoading && <ButtonIconWrapper>{icon}</ButtonIconWrapper>}
      {isLoading ? (
        <ButtonLoader src={require('../../../../assets/images/spinner.gif')} alt="btn-loading-spinner" />
      ) : !!children ? (
        <ChildrenContainer>{children}</ChildrenContainer>
      ) : (
        title
      )}
    </Component>
  );
};

export const MainButton = ({ title, onClick, disabled, isLoading, icon, className, type, children }) =>
  handleLinkWrapping(StyledMainButton, { title, onClick, disabled, isLoading, icon, className, type, children });

export const LinearButton = ({ title, onClick, disabled, isLoading, icon, className, type, children }) =>
  handleLinkWrapping(StyledLinearButton, { title, onClick, disabled, isLoading, icon, className, type, children });

export const TransparentButton = ({ title, onClick, disabled, isLoading, icon, className, type, children }) =>
  handleLinkWrapping(StyledTransparentButton, { title, onClick, disabled, isLoading, icon, className, type, children });

export const PrimaryButton = ({ title, onClick, disabled, isLoading, icon, className, type, children }) =>
  handleLinkWrapping(StyledPrimaryButton, { title, onClick, disabled, isLoading, icon, className, type, children });

export const SecondaryButton = ({ title, onClick, disabled, isLoading, icon, className, type, children }) =>
  handleLinkWrapping(StyledSecondaryButton, { title, onClick, disabled, isLoading, icon, className, type, children });
