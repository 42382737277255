import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`;

export const ContentContainer = styled.div`
  padding-inline: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.9rem;
`;

export const TagsContainer = styled.div`
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`;
