import { createSlice } from '@reduxjs/toolkit';
import { initVoiceCallBuilder } from './builder';

const INIT_STATE = {
  pending: {
    initVoiceCall: false,
  },
};

const voiceCallSlice = createSlice({
  name: 'voiceCall',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    initVoiceCallBuilder(builder);
  },
});

export const selectInitVoiceCallPending = state => state.voiceCallInfo.pending.initVoiceCall;

export default voiceCallSlice.reducer;
