import axiosApiClient from '..';
import { throwApiError, throwErrorIfWrongStatus } from '../util';

const BASE_URL = '/voice-calls';

class VoiceCallService {
  initVoiceCall = async ({ influencerId, secretModeId }) => {
    try {
      const body = { influencerId, secretModeId };
      const response = await axiosApiClient.post(`${BASE_URL}/mini-app/init`, body);
      throwErrorIfWrongStatus(response, 201);
      const { conversationUrl } = response.data.data;
      return conversationUrl;
    } catch (err) {
      throwApiError(err, 'Error occurred while initializing voice call.');
    }
  };
}

const voiceCallService = new VoiceCallService();
export default voiceCallService;
