import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  height: 5rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.8rem;
`;

export const TagsContainer = styled.div`
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`;

export const AllModelsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.9rem;
`;

export const Content = styled.div`
  margin: 0.4rem 1.6rem 6rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
