import React from 'react';
import {
  ButtonLoader,
  ContentContainer,
  ContentContainerFooter,
  ContentContainerHeader,
  IconContainer,
  Microtitle,
  StyledChatButton,
  StyledChatWrapper,
  StyledVideoCallButton,
  StyledVideoCallWrapper,
  StyledVoiceCallButton,
  StyledVoiceCallWrapper,
  Subtitle,
  Title,
} from './ActionButton.styles';

const handleLinkWrapping = (Wrapper, Component, props) => {
  const { title, onClick, disabled, isLoading, icon, subtitle, microtitle, className } = props;

  return (
    <Wrapper className={className}>
      <Component onClick={onClick} disabled={disabled || isLoading} $isLoading={isLoading} $isDisabled={disabled}>
        {isLoading ? (
          <ButtonLoader src={require('../../../../assets/images/spinner.gif')} alt="btn-loading-spinner" />
        ) : (
          <ContentContainer>
            <ContentContainerHeader>
              <IconContainer>{icon}</IconContainer>
              <Title>{title}</Title>
            </ContentContainerHeader>
            <ContentContainerFooter>
              <Subtitle>{subtitle}</Subtitle>
              {microtitle && <Microtitle>{microtitle}</Microtitle>}
            </ContentContainerFooter>
          </ContentContainer>
        )}
      </Component>
    </Wrapper>
  );
};

export const VoiceCallButton = ({ title, onClick, disabled, isLoading, icon, subtitle, microtitle, className }) =>
  handleLinkWrapping(StyledVoiceCallWrapper, StyledVoiceCallButton, {
    title,
    onClick,
    disabled,
    isLoading,
    icon,
    subtitle,
    microtitle,
    className,
  });

export const VideoCallButton = ({ title, onClick, disabled, isLoading, icon, subtitle, microtitle, className }) =>
  handleLinkWrapping(StyledVideoCallWrapper, StyledVideoCallButton, {
    title,
    onClick,
    disabled,
    isLoading,
    icon,
    subtitle,
    microtitle,
    className,
  });

export const ChatButton = ({ title, onClick, disabled, isLoading, icon, subtitle, microtitle, className }) =>
  handleLinkWrapping(StyledChatWrapper, StyledChatButton, {
    title,
    onClick,
    disabled,
    isLoading,
    icon,
    subtitle,
    microtitle,
    className,
  });
