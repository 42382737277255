import { createGlobalStyle } from 'styled-components';
import { theme } from '../styles/constants';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    height: 100%;
    font-size: 62.5%; // 1rem = 10px, 10/16 = 60.25%
    box-sizing: border-box;

    /* @media screen and (max-width: 960px) {
      font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    }

    @media screen and (max-width: 400px) {
      font-size: 50%; //1rem = 8px, 8/16 = 50%
    } */
  }

  body {
    height: 100%;
    font-weight: 300;
    font-size: 1.7rem;
    font-family: Lexend, sans-serif;
    line-height: normal;
    margin: 0;
    background-color: #000;
    /* background: linear-gradient(#231346, #5a34ac); */
    color: ${theme.colors.white};
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  a,
  p,
  svg,
  button,
  div {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #root {
    height: 100%;
  }

  .success-toast-message {
    background-color: #26134e;
    color: #fff;
    border-radius: 1.2rem;
  }

  .success-toast-message-progress {
    background-color: #843efe;
  }
`;

export default GlobalStyles;
