import styled from 'styled-components';
import { Text14Light } from '../utils/texts/text.styles';

export const SwitchContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0.6rem;
`;

export const SwitchButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 2.8rem;
  height: 1.6rem;
  border-radius: 8px;
  border: 1px solid #5d4396;
  background: linear-gradient(180deg, #1b1035 100%, #04020a 100%);
  display: flex;
  align-items: center;
  padding-inline: 1px;
`;

export const SwitchCircle = styled.span`
  height: 1.2rem;
  width: 1.2rem;
  background-color: ${({ $circleColor }) => $circleColor || '#BEA1FF'};
  border-radius: 10px;
  border: 1px solid #fff;
  position: absolute;
  transform: ${({ $rightValueSelected }) => $rightValueSelected && 'translateX(100%)'};
  transition: transform 0.5s ease-in-out;
`;

export const SwitchText = styled(Text14Light)`
  color: #fff;
  width: 3.4rem;
  line-height: 2.2rem;
  text-transform: capitalize;
  letter-spacing: -0.408px;
`;
