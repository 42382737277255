import styled from 'styled-components';
import { Skeleton } from '../Skeleton.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const InfluencerCard = styled(Skeleton)`
  aspect-ratio: 0.48;
  border-radius: 16px;
  width: 100%;
`;

export const AllModelsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const AllModelsTitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
`;

export const TagsContainer = styled.div`
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`;
