import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerService from '../../../api/services/influencer.api';

export const fetchInfluencers = createAsyncThunk('influencer/fetchInfluencers', async (page, { getState }) => {
  const maxReached = getState().influencerInfo.fetchInfluencersInfo.maxReached;
  if (maxReached && page !== 1) {
    throw new Error('There is no more items');
  }
  return await influencerService.fetchInfluencers(page);
});

export const fetchInfluencerByUsername = createAsyncThunk('influencer/fetchInfluencerByUsername', async username => {
  return await influencerService.fetchInfluencerByUsername(username);
});

export const startChat = createAsyncThunk('influencer/startChat', async ({ influencerId, secretModeId }) => {
  return await influencerService.startChat(influencerId, secretModeId);
});

export const fetchInfluencersByTags = createAsyncThunk('influencer/fetchInfluencersByTags', async requestData => {
  return await influencerService.fetchInfluencersByTags(requestData);
});
