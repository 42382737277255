import styled from 'styled-components';
import { Text14Light } from '../../components/utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.6rem 1.2rem;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const SubscriptionCard = styled.div`
  border-radius: 12px;
  border: 1px solid #432181;
  background: linear-gradient(90deg, #1d0f37 0%, #05020b 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Line = styled.div`
  opacity: 0.56;
  background: linear-gradient(90deg, #030108 0%, #a168ff 47.5%, #030108 100%);
  height: 1px;
  width: 100%;
  margin: 1.2rem 0;
`;

export const SubscriptionCardTextContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const SubscriptionCardText = styled(Text14Light)`
  opacity: 0.5;
  color: #c4a0ff;
`;

export const Label = styled(Text14Light)`
  margin-bottom: 1.2rem;
`;
