import { useEffect, useState } from 'react';
import { useCallObject, useLocalSessionId, useParticipantIds } from '@daily-co/daily-react';
import { CALL_STATUS } from '../components/modals/VoiceCallModal/VoiceCallModal';

const useDailyVoiceCall = ({ conversationUrl, setCallStatus }) => {
  const callObject = useCallObject({ options: { audioSource: true, videoSource: false } });
  const localSessionId = useLocalSessionId();
  const participantIds = useParticipantIds({ filter: 'remote' });
  const [callDuration, setCallDuration] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (callObject && conversationUrl) {
      callObject
        .join({ url: conversationUrl })
        .then(() => {
          // console.log('Joined the room successfully');
        })
        .catch(error => {
          console.error('Error joining the room:', error);
          setError(error);
        });

      let callStartTime = null;

      callObject.on('joined-meeting', () => {
        callStartTime = Date.now();
        setCallStatus(CALL_STATUS.ACTIVE);
        const intervalId = setInterval(() => {
          setCallDuration(Math.floor((Date.now() - callStartTime) / 1000));
        }, 1000);

        callObject.on('left-meeting', () => {
          clearInterval(intervalId);
          setCallDuration(0);
          setCallStatus(CALL_STATUS.ENDED);
          callStartTime = null;
        });
      });

      callObject.on('track-started', async e => {
        if (!e || !e.participant) return;
        if (e.participant?.local) return;
        if (e.track.kind !== 'audio') return;
        if (e?.participant?.user_name !== 'Vapi Speaker') return;
        callObject?.sendAppMessage('playable');
      });

      return () => {
        callObject.leave();
        callObject.destroy();
      };
    }
  }, [callObject, conversationUrl, setCallStatus]);

  return {
    dailyCallObject: callObject,
    dailyLocalSessionId: localSessionId,
    dailyParticipantIds: participantIds,
    dailyError: error,
    callDuration,
  };
};

export default useDailyVoiceCall;
