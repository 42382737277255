import { fetchInfluencerByUsername, fetchInfluencers, fetchInfluencersByTags, startChat } from './asyncThunks';

export const fetchInfluencersBuilder = builder => {
  builder.addCase(fetchInfluencers.pending, state => {
    state.fetchInfluencersInfo.pending = true;
  });
  builder.addCase(fetchInfluencers.fulfilled, (state, { payload }) => {
    const { influencers, page } = payload;
    state.fetchInfluencersInfo.pending = false;
    state.fetchInfluencersInfo.data =
      page === 1 ? influencers.data : [...state.fetchInfluencersInfo.data, ...influencers.data];
    state.fetchInfluencersInfo.maxReached = influencers.maxReached;
    state.fetchInfluencersInfo.page = page + 1;
  });
  builder.addCase(fetchInfluencers.rejected, state => {
    state.fetchInfluencersInfo.pending = false;
  });
};

export const fetchInfluencerByUsernameBuilder = builder => {
  builder.addCase(fetchInfluencerByUsername.pending, state => {
    state.selectedInfluencerInfo.pending = true;
  });
  builder.addCase(fetchInfluencerByUsername.fulfilled, (state, { payload }) => {
    state.selectedInfluencerInfo.pending = false;
    state.selectedInfluencerInfo.influencer = payload;
  });
  builder.addCase(fetchInfluencerByUsername.rejected, state => {
    state.selectedInfluencerInfo.pending = false;
  });
};

export const startChatBuilder = builder => {
  builder.addCase(startChat.pending, state => {
    state.pending.startChat = true;
  });
  builder.addCase(startChat.fulfilled, state => {
    state.pending.startChat = false;
  });
  builder.addCase(startChat.rejected, state => {
    state.pending.startChat = false;
  });
};

export const fetchInfluencersByTagsBuilder = builder => {
  builder.addCase(fetchInfluencersByTags.pending, state => {
    state.fetchInfluencersByTagsInfo.pending = true;
  });
  builder.addCase(fetchInfluencersByTags.fulfilled, (state, { payload }) => {
    const { influencers } = payload;
    state.fetchInfluencersByTagsInfo.pending = false;
    state.fetchInfluencersByTagsInfo.data = influencers;
  });
  builder.addCase(fetchInfluencersByTags.rejected, state => {
    state.fetchInfluencersByTagsInfo.pending = false;
  });
};
