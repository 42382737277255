import styled from 'styled-components';

export const Container = styled.div`
  width: 24rem;
  height: 24rem;
  border: 14px solid #53319c;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  border-radius: 50%;
  padding: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 18.6rem;
  height: 18.6rem;
  background: linear-gradient(180deg, #ab75ff 0%, #4d2595 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
