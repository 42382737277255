import React from 'react';
import { Container, InnerContainer } from './EnableSound.styles';
import { Text22Bold } from '../../utils/texts/text.styles';

function EnableSound({ onClick, className }) {
  return (
    <Container onClick={onClick} className={className}>
      <InnerContainer>
        <Text22Bold>Press to Start</Text22Bold>
      </InnerContainer>
    </Container>
  );
}

export default EnableSound;
