import styled from 'styled-components';

const StyledBasicText = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  line-height: normal;
`;

export const Text10Regular = styled(StyledBasicText)`
  font-size: 1rem;
  font-weight: 400;
`;

export const Text12Light = styled(StyledBasicText)`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const Text12ExtraBold = styled(StyledBasicText)`
  font-size: 1.2rem;
  font-weight: 700;
`;

export const Text12Regular = styled(StyledBasicText)`
  font-size: 1.2rem;
  font-weight: 400;
`;

export const Text13Light = styled(StyledBasicText)`
  font-size: 1.3rem;
  font-weight: 300;
`;

export const Text13Regular = styled(StyledBasicText)`
  font-size: 1.3rem;
  font-weight: 400;
`;

export const Text14Light = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 300;
`;

export const Text14Regular = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const Text14Bold = styled(StyledBasicText)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const Text15Light = styled(StyledBasicText)`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const Text16Light = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 300;
`;

export const Text16Regular = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Text16Bold = styled(StyledBasicText)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const Text17Regular = styled(StyledBasicText)`
  font-size: 1.7rem;
  font-weight: 400;
`;

export const Text18Regular = styled(StyledBasicText)`
  font-size: 1.8rem;
  font-weight: 400;
`;

export const Text18Bold = styled(StyledBasicText)`
  font-size: 1.8rem;
  font-weight: 500;
`;

export const Text15Bold = styled(StyledBasicText)`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const Text17Bold = styled(StyledBasicText)`
  font-size: 1.7rem;
  font-weight: 500;
`;

export const Text20Bold = styled(StyledBasicText)`
  font-size: 2rem;
  font-weight: 500;
`;

export const Text22Bold = styled(StyledBasicText)`
  font-size: 2.2rem;
  font-weight: 500;
`;

export const Text24Bold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Text24ExtraBold = styled(StyledBasicText)`
  font-size: 2.4rem;
  font-weight: 600;
`;

export const Text32Bold = styled(StyledBasicText)`
  font-size: 3.2rem;
  font-weight: 500;
`;

export const Text38ExtraBold = styled(StyledBasicText)`
  font-size: 3.8rem;
  font-weight: 600;
  letter-spacing: -0.38px;
`;

export const Text48ExtraBold = styled(StyledBasicText)`
  font-size: 4.8rem;
  font-weight: 600;
`;
