import styled from 'styled-components';
import { Text12Regular, Text14Regular } from '../../texts/text.styles';

export const StyledBasicActionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 5.6rem;
  padding: 1px;
  overflow: hidden;
  border-radius: 16px;
  max-width: 12rem;
`;

export const StyledBasicActionButton = styled.button`
  position: absolute;
  inset: 1px;
  padding: ${props => (props.$isLoading ? '0 3.4rem' : '0')};
  color: #fff;
  border-radius: 16px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    font-weight: 300;
    color: #b3a2cf;
  }
`;

export const StyledVoiceCallWrapper = styled(StyledBasicActionWrapper)`
  background: linear-gradient(0deg, #ab75ff 0%, #4d2595 100%);
`;

export const StyledVideoCallWrapper = styled(StyledBasicActionWrapper)`
  background-color: #532b9a;
`;

export const StyledChatWrapper = styled(StyledBasicActionWrapper)`
  background-color: #4d2595;
`;

export const StyledVoiceCallButton = styled(StyledBasicActionButton)`
  background: linear-gradient(180deg, #ab75ff 0%, #4d2595 100%);
`;

export const StyledVideoCallButton = styled(StyledBasicActionButton)`
  background: linear-gradient(0deg, #4d2595 0%, #180f2a 100%);
`;

export const StyledChatButton = styled(StyledBasicActionButton)`
  background: linear-gradient(180deg, #4d2595 0%, #180f2a 100%);
`;

// Content

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

export const ContentContainerHeader = styled.div`
  display: flex;
  gap: 3px;
  height: 20px;
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Text14Regular)`
  color: #fff;
  line-height: 2rem;
`;

export const ContentContainerFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled(Text12Regular)`
  color: #dcc6ff;
  color: color(display-p3 0.8471 0.779 1);
  letter-spacing: -0.42px;
  line-height: 1.2rem;
`;

export const Microtitle = styled(Subtitle)`
  font-size: 1rem;
  margin-left: 2px;
`;

export const ButtonLoader = styled.img`
  height: 12rem;
  aspect-ratio: 1 / 1;
`;
