import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AboutMeText,
  BackContainer,
  BalanceValueText,
  BasicInfoContainer,
  BuyMoreCreditsIcon,
  Container,
  Content,
  CreditsContainer,
  Header,
  OnlineContainer,
  OnlineText,
  NameContainer,
  NameText,
  SecretModes,
  SecretModeItems,
  SecretModeButton,
  SectionTitle,
  AboutMeContainer,
  ButtonsContainer,
  ActionsContainer,
  LottieContainer,
  Shadow,
  Avatar,
  RightSide,
  Grid,
  CoverImage,
  Circle,
  VoiceCallBtn,
  VideoCallBtn,
  ChatBtn,
} from './InfluencerProfilePage.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchInfluencerByUsername, startChat } from '../../store/slices/influencer/asyncThunks';
import { selectSelectedInfluencerInfo, selectStartChatPending } from '../../store/slices/influencer/slice';
import { selectUser } from '../../store/slices/user/slice';
import { roundNumber } from '../../utils/util';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/message_circle.svg';
import { ReactComponent as VoiceCallIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as VideoCallIcon } from '../../assets/icons/video.svg';
import Switch from '../../components/Switch/Switch';
import { changeUserResponseFormat, fetchUserInfo } from '../../store/slices/user/asyncThunks';
import Lottie from 'lottie-react';
import logoAnimation from '../../assets/gifs/logo.json';
import { selectInitVoiceCallPending } from '../../store/slices/voiceCall/slice';
import RedirectToWebsiteModal from '../../components/modals/RedirectToWebsiteModal/RedirectToWebsiteModal';
import VoiceCallModal from '../../components/modals/VoiceCallModal/VoiceCallModal';
import { notifyError } from '../../utils/notify';
import VideoCallModal from '../../components/modals/VideoCallModal/VideoCallModal';
// import VideoCallModal from '../../components/modals/VideoCallModal/VideoCallModal';
const TelegramWebApp = window.Telegram.WebApp;

const CHAT_MODE = {
  VOICE: 'VOICE',
  TEXT: 'TEXT',
};

const ACTIVE_MODAL = {
  VOICE_CALL: 'VOICE_CALL',
  VIDEO_CALL: 'VIDEO_CALL',
};

function InfluencerProfilePage() {
  const { influencerUsername } = useParams();
  const user = useSelector(selectUser);
  const { influencer, pending: fetchInfluencerPending } = useSelector(selectSelectedInfluencerInfo);
  const startChatPending = useSelector(selectStartChatPending);
  const initVoiceCallPending = useSelector(selectInitVoiceCallPending);
  const initVideoCallPending = false;
  const [selectedSecretMode, setSelectedSecretMode] = useState(null);
  const [chatMode, setChatMode] = useState(user.responseFormat);
  const [showRedirectToWebAppModal, setShowRedirectToWebAppModal] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const prevActiveModalRef = useRef();
  const voiceCallModalRef = useRef();
  const videoCallModalRef = useRef();
  // const [showVoiceCallModal, setShowVoiceCallModal] = useState(false);
  // const [showVideoCallModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeModal && prevActiveModalRef.current) {
      const intervalId = setInterval(() => {
        dispatch(fetchUserInfo());
      }, 3000);

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 27000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [activeModal, dispatch]);

  useEffect(() => {
    prevActiveModalRef.current = activeModal;
  }, [activeModal]);

  useEffect(() => {
    dispatch(fetchInfluencerByUsername(influencerUsername))
      .unwrap()
      .then(payload => {
        if (payload.userInfluencer?.activeSecretModeId) {
          const foundSelectedSecretMode = payload.secretModes.find(
            secretMode => secretMode.id === payload.userInfluencer.activeSecretModeId,
          );
          setSelectedSecretMode(foundSelectedSecretMode);
        } else {
          const defaultSecretMode = payload.secretModes.find(secretMode => secretMode.isDefault);
          setSelectedSecretMode(defaultSecretMode);
        }
      })
      .catch(err => console.log(err));
  }, [dispatch, influencerUsername]);

  const onBuyMoreCredits = () => {
    setShowRedirectToWebAppModal(true);
  };

  const onChangeResponseFormat = () => {
    const newChatMode = chatMode === CHAT_MODE.TEXT ? CHAT_MODE.VOICE : CHAT_MODE.TEXT;
    setChatMode(newChatMode);
    dispatch(changeUserResponseFormat({ responseFormat: newChatMode }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        setChatMode(chatMode);
        notifyError(err.message);
      });
  };

  const onChatClicked = () => {
    try {
      if (influencer.userInfluencer?.activeSecretModeId !== selectedSecretMode.id) {
        dispatch(startChat({ influencerId: influencer.id, secretModeId: selectedSecretMode.id }))
          .unwrap()
          .then(() => {
            TelegramWebApp.openTelegramLink(influencer.telegramProfileUrl);
            TelegramWebApp.close();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        TelegramWebApp.openTelegramLink(influencer.telegramProfileUrl);
        TelegramWebApp.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Voice call modal
  const onVoiceCallClicked = useCallback(() => {
    setActiveModal(ACTIVE_MODAL.VOICE_CALL);
    setTimeout(() => voiceCallModalRef.current.startCall(), 200);
  }, []);

  const onCloseVoiceModal = useCallback(() => {
    setActiveModal(null);
    voiceCallModalRef.current.hide();
  }, []);

  // Video call modal
  const onVideoCallClicked = useCallback(() => {
    setActiveModal(ACTIVE_MODAL.VIDEO_CALL);
    setTimeout(() => videoCallModalRef.current.startCall(), 200);
  }, []);

  const onCloseVideoModal = useCallback(() => {
    setActiveModal(null);
    videoCallModalRef.current.hide();
  }, []);

  if (fetchInfluencerPending) {
    return (
      <LottieContainer>
        <Lottie animationData={logoAnimation} loop={true} />
      </LottieContainer>
    );
  }

  return (
    <Container>
      {influencer && selectedSecretMode && (
        <>
          <Content>
            <Shadow />
            <Header>
              <BackContainer onClick={() => navigate(-1)}>
                <ChevronLeftIcon />
              </BackContainer>
              <ActionsContainer>
                <Switch
                  leftValue={CHAT_MODE.VOICE}
                  rightValue={CHAT_MODE.TEXT}
                  selectedValue={chatMode}
                  onChange={onChangeResponseFormat}
                />
                <CreditsContainer onClick={onBuyMoreCredits}>
                  <BalanceValueText>{user.credits} 💎</BalanceValueText>
                  <BuyMoreCreditsIcon />
                </CreditsContainer>
              </ActionsContainer>
            </Header>
            <BasicInfoContainer>
              <Avatar src={influencer.profilePhoto} />
              <RightSide>
                <NameContainer>
                  <NameText>
                    {influencer.name}, {influencer.yearsOld}
                  </NameText>
                  <VerifiedIcon />
                </NameContainer>
                <OnlineContainer>
                  <Circle />
                  <OnlineText>Online</OnlineText>
                </OnlineContainer>
              </RightSide>
            </BasicInfoContainer>
            <AboutMeContainer>
              <AboutMeText>{influencer.aboutMe}</AboutMeText>
            </AboutMeContainer>
            <SecretModes>
              <SectionTitle>Secret Modes</SectionTitle>
              <SecretModeItems>
                {influencer.secretModes.map(sm => {
                  return (
                    <SecretModeButton
                      key={sm.id}
                      onClick={() => setSelectedSecretMode(sm)}
                      $selected={sm === selectedSecretMode}>
                      {sm.symbol}
                      {` `}
                      {sm.name}
                    </SecretModeButton>
                  );
                })}
              </SecretModeItems>
            </SecretModes>
            <ButtonsContainer>
              <VoiceCallBtn
                title="Call"
                onClick={onVoiceCallClicked}
                isLoading={initVoiceCallPending}
                icon={<VoiceCallIcon />}
                subtitle={`${roundNumber(selectedSecretMode.pricing.voiceCallPerSecond * 60, 0)} 💎`}
                microtitle={'/ min'}
              />
              {influencer.callOptions.isVideoCallEnabled && (
                <VideoCallBtn
                  title="Video"
                  onClick={onVideoCallClicked}
                  isLoading={initVideoCallPending}
                  icon={<VideoCallIcon />}
                  subtitle={`${roundNumber(selectedSecretMode.pricing.videoCallPerSecond * 60, 0)} 💎`}
                  microtitle={'/ min'}
                />
              )}
              <ChatBtn
                title="Chat"
                onClick={onChatClicked}
                isLoading={startChatPending}
                icon={<ChatIcon />}
                subtitle={'PPV'}
              />
            </ButtonsContainer>
            <Grid>
              {influencer.albumMedia.photos.cover.map((photo, index) => {
                return <CoverImage key={index} src={photo} />;
              })}
            </Grid>
          </Content>
        </>
      )}
      {activeModal === ACTIVE_MODAL.VOICE_CALL && (
        <VoiceCallModal
          ref={voiceCallModalRef}
          onClose={onCloseVoiceModal}
          influencer={influencer}
          secretMode={selectedSecretMode}
          shouldInitDaily={activeModal === ACTIVE_MODAL.VOICE_CALL}
        />
      )}
      {activeModal === ACTIVE_MODAL.VIDEO_CALL && (
        <VideoCallModal
          ref={videoCallModalRef}
          onClose={onCloseVideoModal}
          influencer={influencer}
          secretMode={selectedSecretMode}
          shouldInitDaily={activeModal === ACTIVE_MODAL.VIDEO_CALL}
        />
      )}
      <RedirectToWebsiteModal show={showRedirectToWebAppModal} setShow={setShowRedirectToWebAppModal} />
    </Container>
  );
}

export default InfluencerProfilePage;
