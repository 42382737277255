import styled from 'styled-components';
import { Text12Regular, Text14Light } from '../../utils/texts/text.styles';
import EnableSound from '../../helper/EnableSound/EnableSound';
import { DailyVideo } from '@daily-co/daily-react';

export const Background = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
  background-color: #0e081c;
  /* background-color: #000; */
`;

export const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled(Row)`
  justify-content: space-between;
  z-index: 5;
  margin-top: 1.6rem;
  width: 100%;

  @media only screen and (max-width: 500px) {
    margin-top: 1.2rem;
  }
`;

export const HeaderLeftBox = styled(Row)`
  height: 4.6rem;
  gap: 8px;
`;

export const HeaderInfoBox = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const HeaderInfluencerInfo = styled(Row)`
  gap: 8px;
`;

export const InfluencerPhoto = styled.img`
  height: 4.6rem;
  width: 4.6rem;
  border: 1px solid #c4a0ff;
  border-radius: 50%;
  object-fit: cover;
`;

export const StatusText = styled(Text12Regular)`
  color: #fff;
`;

export const InfluencerCameraContainer = styled.div`
  position: relative;
  margin-top: 2.4rem;
  width: 100%;
  aspect-ratio: 1;
  z-index: 3;
  border-radius: 1.2rem;
  overflow: hidden;
`;

export const InfluencerCameraContainerNoteContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 9rem;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: linear-gradient(180deg, #00000000 0%, #0e0819 100%);
`;

export const InfluencerCameraContainerNoteText = styled(Text12Regular)`
  margin-bottom: 1.2rem;
  text-align: center;
`;

export const Camera = styled(DailyVideo)`
  width: 100%;
  height: 100%;
`;

export const UserCameraContainer = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 12.4rem;
  width: 7rem;
  height: 9rem;
  border-radius: 1.2rem;
  overflow: hidden;
  z-index: 4;
`;

export const ButtonContainer = styled.div`
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: auto;
  margin-bottom: 2.2rem;
`;

export const EndCallButton = styled.button`
  border-radius: 2.4rem;
  border: 1px solid #87171e;
  background: #87171e;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
  cursor: pointer;
`;

export const ButtonText = styled(Text14Light)`
  letter-spacing: -0.4px;
  user-select: none;
`;

export const EnableSoundWrapper = styled.div`
  z-index: 100;
  position: absolute;
  inset: 0;
  background-color: #00000070;
  width: 100%;
  height: 100%;
`;

export const EnableSoundComp = styled(EnableSound)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: -2rem;

  div {
    width: 20rem;
    height: 20rem;
  }
`;
