import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 4.8rem;
  padding: 1.6rem 1.2rem;
  border: 1px solid #7b42f5;
  border-radius: 1.2rem;
  cursor: pointer;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  background-color: ${props => (props.$isActive ? '#7b42f5' : 'transparent')};
`;

export const Photo = styled.p`
  font-size: 1.6rem;
  line-height: 1.6rem;
`;

export const NameText = styled.p`
  font-size: 1.6rem;
  line-height: 2rem;
  margin-left: 1.6rem;
`;

export const PriceText = styled.p`
  margin-left: auto;
  font-size: 1.6rem;
  line-height: 2rem;
`;

export const MinuteText = styled(PriceText)`
  margin-left: 0.6rem;
  opacity: 0.4;
`;
