import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Content, ContentContainer, TagsContainer } from './PersonalitiesPage.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencersByTagsInfo } from '../../store/slices/influencer/slice';
import { fetchInfluencersByTags } from '../../store/slices/influencer/asyncThunks';
import PersonalityTags from '../../components/PersonalityTags/PersonalityTags';
import PersonalitiesPageSkeleton from '../../components/skeleton/PersonalitiesPageSkeleton/PersonalitiesPageSkeleton';
import InfluencerCard from '../../components/InfluencerCard/InfluencerCard';
import Header from '../../components/Header/Header';

const PersonalitiesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { personalityName } = useParams();
  const { state } = useLocation();
  const initTag = state?.initTag;

  const { pending, data } = useSelector(selectFetchInfluencersByTagsInfo);
  const onPersonalityTagClick = value => {
    navigate(`/personality/${value}`);
  };

  useEffect(() => {
    if (personalityName) {
      dispatch(fetchInfluencersByTags({ appearanceTags: [], personalityTags: [personalityName] }));
    }
  }, [personalityName, dispatch]);

  const onBack = () => {
    navigate(`/`);
  };

  return (
    <Container>
      <Header onBack={onBack} pageId="personalitiesPage" />
      <ContentContainer>
        <TagsContainer>
          <PersonalityTags onTagClick={onPersonalityTagClick} selectedTag={personalityName} initTag={initTag} />
        </TagsContainer>
        {pending ? (
          <Content>
            <PersonalitiesPageSkeleton />
          </Content>
        ) : (
          <Content>
            {data.map(influencer => {
              return (
                <InfluencerCard
                  key={influencer.id}
                  name={influencer.name}
                  isVerified={influencer.isVerified}
                  image={influencer.image}
                  username={influencer.username}
                  age={influencer.age}
                  aboutMe={influencer.aboutMe}
                  aspectRatio={0.48}
                  category={influencer.category}
                  callOptions={influencer.callOptions}
                />
              );
            })}
          </Content>
        )}
      </ContentContainer>
    </Container>
  );
};

export default PersonalitiesPage;
