import { createSlice } from '@reduxjs/toolkit';
import {
  changeUserResponseFormatBuilder,
  createWebsiteUrlBuilder,
  fetchUserInfoBuilder,
  setUserPhoneNumberBuilder,
} from './builder';

const INIT_STATE = {
  fetchUserInfoPending: false,
  user: {
    id: null,
    username: null,
    credits: null,
    isPremium: false,
    phoneNumber: null,
    activeInfluencer: null,
  },
  pending: {
    setUserPhoneNumber: false,
    initCall: false,
    createWebsiteUrl: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    setActiveInfluencer: (state, { payload }) => {
      state.user.activeInfluencer = payload;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
    setUserPhoneNumberBuilder(builder);
    createWebsiteUrlBuilder(builder);
    changeUserResponseFormatBuilder(builder);
  },
});

export const { setActiveInfluencer } = userSlice.actions;

export const selectFetchUserInfoPending = state => state.userInfo.fetchUserInfoPending;
export const selectUser = state => state.userInfo.user;
export const selectActiveInfluencer = state => state.userInfo.user.activeInfluencer;
export const selectSetUserPhoneNumberPending = state => state.userInfo.pending.setUserPhoneNumber;
export const selectCreateWebsiteUrlPending = state => state.userInfo.pending.createWebsiteUrl;

export default userSlice.reducer;
