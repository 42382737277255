import styled from 'styled-components';

export const StyledBasicButton = styled.button`
  position: relative;
  width: 100%;
  height: 4.8rem;
  padding: ${props => (props.$isLoading ? '0 3.4rem' : '1.2rem')};
  border-radius: 16px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    border: 1px solid rgba(132, 62, 254, 0.15);
    background: #1d0f37;
    box-shadow: none;
    font-weight: 300;
    color: #b3a2cf;
  }
`;

export const StyledMainButton = styled(StyledBasicButton)`
  border: 1px solid #843efe;
  background: linear-gradient(180deg, #9861f8 0%, #4d2495 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.23);
`;

export const StyledLinearButton = styled(StyledBasicButton)`
  background: linear-gradient(
      269deg,
      color(display-p3 0.1373 0.0745 0.2745) -32.78%,
      color(display-p3 0 0 0 / 0) 82.28%
    ),
    color(display-p3 0.4824 0.2588 0.9608);
`;

export const StyledTransparentButton = styled(StyledBasicButton)`
  background-color: transparent;
`;

export const StyledPrimaryButton = styled(StyledBasicButton)`
  background: linear-gradient(180deg, #9064ef 0%, #48278f 100%);
  border: 1px solid #7b42f5;
`;

export const StyledSecondaryButton = styled(StyledBasicButton)`
  background: linear-gradient(180deg, #311768 0%, #1f0f42 100%);
  border: 1px solid #3b1291;
`;

export const ButtonIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0%, -44%);
`;

export const ButtonLoader = styled.img`
  height: 12rem;
  aspect-ratio: 1 / 1;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.6rem;
`;
