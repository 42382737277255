import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfluencers } from '../../store/slices/influencer/asyncThunks';
import {
  AllModelsSection,
  Container,
  Content,
  Grid,
  Header,
  IconWrapper,
  TagsContainer,
  TitleContainer,
} from './InfluencersListPage.styles';
import { useNavigate } from 'react-router-dom';
import LogoComponent from '../../components/LogoComponent/LogoComponent';
import { Text18Bold } from '../../components/utils/texts/text.styles';
import { ReactComponent as TrendingIcon } from '../../assets/icons/PersonalityTags/trending.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-circle.svg';
import { selectFetchInfluencersInfo } from '../../store/slices/influencer/slice';
import InfluencerCard from '../../components/InfluencerCard/InfluencerCard';
import AppearanceTags from '../../components/AppearanceTags/AppearanceTags';
import PersonalityTags from '../../components/PersonalityTags/PersonalityTags';
import HomePageSkeleton from '../../components/skeleton/HomePageSkeleton/HomePageSkeleton';

function InfluencersListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, page, maxReached, pending } = useSelector(selectFetchInfluencersInfo);

  const observer = useRef();

  useEffect(() => {
    dispatch(fetchInfluencers(1));
  }, [dispatch]);

  const lastBookElementRef = useCallback(
    node => {
      if (pending) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !maxReached) {
          dispatch(fetchInfluencers(page));
        }
      });
      if (node) observer.current.observe(node);
    },
    [pending, maxReached, dispatch, page],
  );
  const onPersonalityTagClick = value => {
    navigate(`/personality/${value}`, { state: { initTag: true } });
  };
  const onAppearanceTagClick = value => {
    navigate(`/hashtag/${value}`, { state: { initTag: true } });
  };

  const onProfileClick = () => {
    navigate(`/profile`);
  };

  return (
    <Container>
      <Header>
        <LogoComponent fullLogo id="homepage" />
        <IconWrapper onClick={onProfileClick}>
          <UserIcon />
        </IconWrapper>
      </Header>
      <Content>
        {pending && page === 1 ? (
          <HomePageSkeleton />
        ) : (
          <>
            <TagsContainer>
              <PersonalityTags onTagClick={onPersonalityTagClick} />
            </TagsContainer>
            <AllModelsSection>
              <TitleContainer>
                <Text18Bold>Explore</Text18Bold>
                <TrendingIcon width={20} height={20} />
              </TitleContainer>
              <Grid>
                {data.slice(0, 8).map((influencer, index) => {
                  return (
                    <InfluencerCard
                      key={index}
                      name={influencer.name}
                      isVerified={influencer.isVerified}
                      username={influencer.username}
                      aboutMe={influencer.aboutMe}
                      age={influencer.age}
                      aspectRatio={0.48}
                      image={influencer.image}
                      category={influencer.category}
                      callOptions={influencer.callOptions}
                    />
                  );
                })}
              </Grid>
              <TagsContainer>
                <AppearanceTags onTagClick={onAppearanceTagClick} />
              </TagsContainer>
              {data.length > 8 && (
                <Grid>
                  {data.slice(8, data.length).map((influencer, index) => {
                    return (
                      <InfluencerCard
                        key={index}
                        name={influencer.name}
                        isVerified={influencer.isVerified}
                        username={influencer.username}
                        aboutMe={influencer.aboutMe}
                        age={influencer.age}
                        aspectRatio={0.48}
                        image={influencer.image}
                        category={influencer.category}
                        callOptions={influencer.callOptions}
                        innerRef={index === data.slice(8, data.length).length - 1 ? lastBookElementRef : null}
                      />
                    );
                  })}
                </Grid>
              )}
            </AllModelsSection>
          </>
        )}
      </Content>
    </Container>
  );
}

export default InfluencersListPage;
