import styled from 'styled-components';
import { ReactComponent as BuyCreditsIcon } from '../../assets/icons/buy-credits.svg';
import {
  Text12Light,
  Text12Regular,
  Text13Light,
  Text13Regular,
  Text14Regular,
  Text20Bold,
} from '../../components/utils/texts/text.styles';
import { VoiceCallButton, ChatButton, VideoCallButton } from '../../components/utils/buttons/ActionButton/ActionButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2.6rem;
  height: 100%;
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 19.2rem;
  background: radial-gradient(40.05% 100% at 50% 0%, #341569 0%, #07030e 100%);
  background: radial-gradient(
    40.05% 100% at 50% 0%,
    color(display-p3 0.1882 0.0902 0.3961) 0%,
    color(display-p3 0.0235 0.0118 0.051) 100%
  );
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  z-index: 1;
`;

export const BackContainer = styled.div`
  cursor: pointer;
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const CreditsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.6rem;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  gap: 0.5rem;
`;

export const BalanceText = styled.p`
  color: #020104;
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: -0.42px;
`;

export const BalanceValueText = styled(Text14Regular)`
  color: #fff;
  letter-spacing: -0.42px;
`;

export const BuyMoreCreditsIcon = styled(BuyCreditsIcon)`
  margin-left: 0.4rem;
`;

export const PriceText = styled.p`
  margin-left: auto;
  font-size: 1.6rem;
  line-height: 2rem;
`;

export const MinuteText = styled(PriceText)`
  margin-left: 0.6rem;
  opacity: 0.4;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const Avatar = styled.img`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
`;

export const BasicInfoContainer = styled.div`
  margin-inline: 1.5rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  z-index: 1;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const OnlineContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;

export const Circle = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: #00da00;
`;

export const NameText = styled(Text20Bold)`
  line-height: 2.4rem;
`;

export const OnlineText = styled(Text13Regular)`
  letter-spacing: -0.408px;
  color: #00da00;
`;

export const SecretModes = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.4rem;
  margin-left: 1.5rem;
  margin-top: 1.2rem;
  margin-right: 2.1rem;
  z-index: 1;
`;

export const SectionTitle = styled(Text12Light)`
  color: #cfc3e9;
`;

export const SecretModeItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
`;

export const SecretModeButton = styled.button`
  border-radius: 10px;
  border: ${({ $selected }) => ($selected ? '1px solid #7438DF;' : '1px solid #2c1655')};
  background: ${({ $selected }) =>
    $selected
      ? 'linear-gradient(0deg, #2B1754 0%, #2B1754 100%), #5E3D99;'
      : 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36'};
  padding: 0.5rem 1.4rem 0 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  height: 3rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 1.8rem;
  letter-spacing: -0.408px;
`;

export const AboutMeContainer = styled.div`
  z-index: 1;
  margin-left: 1.5rem;
  margin-right: 2.1rem;
  margin-top: 1.2rem;
`;

export const AboutMeText = styled(Text13Light)`
  line-height: 1.7rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-inline: 1.6rem;
  margin-top: 1.8rem;
  gap: 0.8rem;
`;

export const ButtonContainer = styled(Text14Regular)``;

export const ActionButton = styled.button`
  flex-direction: column;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  height: 100%;
  width: 100%;
  padding: 0.8rem 1.2rem 0.8rem 1rem;
  border: none;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  padding: 0.1rem;
  height: 5.4rem;
  flex: 1;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const PriceLabel = styled(Text12Regular)`
  color: #dcc6ff;
  letter-spacing: -0.42px;
  line-height: 1.4rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 20rem;
    height: 20rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.1rem;
  margin-top: 2rem;
`;

export const CoverImage = styled.img`
  width: 100%;
  aspect-ratio: 0.7;
  object-fit: cover;
  overflow: hidden;
`;

export const ButtonLoader = styled.img`
  height: 12rem;
  aspect-ratio: 1 / 1;
`;

export const VoiceCallBtn = styled(VoiceCallButton)`
  flex: 1;
  max-width: none;
  width: none;
`;

export const VideoCallBtn = styled(VideoCallButton)`
  flex: 1;
  max-width: none;
  width: none;
`;

export const ChatBtn = styled(ChatButton)`
  flex: 1;
  max-width: none;
  width: none;
`;
