import { changeUserResponseFormat, createWebsiteUrl, fetchUserInfo, setUserPhoneNumber } from './asyncThunks';

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.pending, state => {
    state.fetchUserInfoPending = true;
  });
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    state.fetchUserInfoPending = false;
    state.user = payload;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.fetchUserInfoPending = false;
  });
};

export const setUserPhoneNumberBuilder = builder => {
  builder.addCase(setUserPhoneNumber.pending, state => {
    state.pending.setUserPhoneNumber = true;
  });
  builder.addCase(setUserPhoneNumber.fulfilled, (state, { payload }) => {
    state.pending.setUserPhoneNumber = false;
    state.user.phoneNumber = payload;
  });
  builder.addCase(setUserPhoneNumber.rejected, state => {
    state.pending.setUserPhoneNumber = false;
  });
};

export const createWebsiteUrlBuilder = builder => {
  builder.addCase(createWebsiteUrl.pending, state => {
    state.pending.createWebsiteUrl = true;
  });
  builder.addCase(createWebsiteUrl.fulfilled, state => {
    state.pending.createWebsiteUrl = false;
  });
  builder.addCase(createWebsiteUrl.rejected, state => {
    state.pending.createWebsiteUrl = false;
  });
};

export const changeUserResponseFormatBuilder = builder => {
  builder.addCase(changeUserResponseFormat.fulfilled, (state, { payload }) => {
    const { responseFormat } = payload;
    state.user.responseFormat = responseFormat;
  });
};
