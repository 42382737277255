import React from 'react';
import {
  AddCreditButton,
  Container,
  DiamondText,
  LogoContainer,
  YourBalance,
  YourBalanceText,
} from './YourBalanceCard.styles';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import { Text32Bold } from '../../utils/texts/text.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus_circle.svg';

const YourBalanceCard = ({ onAddBalanceClick }) => {
  const { credits } = useSelector(selectUser);

  return (
    <Container>
      <LogoContainer>
        <LogoIcon width={120} height={120} />
      </LogoContainer>
      <YourBalance>
        <Text32Bold>
          {credits}
          <DiamondText>💎</DiamondText>
        </Text32Bold>
        <YourBalanceText>YOUR BALANCE</YourBalanceText>
      </YourBalance>
      <AddCreditButton onClick={onAddBalanceClick}>
        <PlusIcon />
        Add credit
      </AddCreditButton>
    </Container>
  );
};

export default YourBalanceCard;
