import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import BaseCallModal from '../BaseCallModal/BaseCallModal';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import PermissionsModal from '../PermissionsModal/PermissionsModal';
import {
  Background,
  BackgroundImage,
  ButtonContainer,
  ButtonText,
  Camera,
  EnableSoundComp,
  EnableSoundWrapper,
  EndCallButton,
  Header,
  HeaderInfluencerInfo,
  HeaderInfoBox,
  HeaderLeftBox,
  InfluencerCameraContainer,
  InfluencerCameraContainerNoteContainer,
  InfluencerCameraContainerNoteText,
  InfluencerPhoto,
  LottieContainer,
  StatusText,
  UserCameraContainer,
} from './VideoCallModal.styles';
import { formatDuration, getMissingPermissions } from '../../../utils/util';
import { notifyError, notifyInvisible } from '../../../utils/notify';
import { DailyAudioTrack } from '@daily-co/daily-react';
import useDailyVideoCall from '../../../hooks/useDailyVideoCall';
import { initVideoCall } from '../../../store/slices/videoCall/asyncThunks';
import { Text12Light, Text14Bold } from '../../utils/texts/text.styles';
import { ReactComponent as VerifiedIcon } from '../../../assets/icons/verified.svg';
import logoAnimation from '../../../assets/gifs/logo-pulse.json';
import Lottie from 'lottie-react';
import Stars from '../../../assets/images/stars.png';

export const CALL_STATUS = {
  INACTIVE: 'INACTIVE',
  LOADING: 'LOADING',
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  CANCEL: 'CANCEL',
};

const VideoCallModal = forwardRef(({ onClose, influencer, secretMode }, ref) => {
  const [callStatus, setCallStatus] = useState(CALL_STATUS.INACTIVE);
  const [conversationUrl, setConversationUrl] = useState(null);
  const { dailyLocalSessionId, dailyParticipantIds, dailyError, callDuration } = useDailyVideoCall({
    conversationUrl,
    setCallStatus,
  });
  const [isAndroid, setIsAndroid] = useState(false);
  const [showNoteMessage, setShowNoteMessage] = useState(true);
  const [shouldDisplayAndroidConfirmation, setShouldDisplayAndroidConfirmation] = useState(false);
  const baseModalRef = useRef();
  const permissionsModalRef = useRef();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    async startCall() {
      try {
        baseModalRef.current.show();
        const { missingPermissions, isAndroid } = await getMissingPermissions(['microphone', 'camera']);
        setIsAndroid(isAndroid);
        if (!missingPermissions.length) {
          await startVideoCall();
        } else {
          permissionsModalRef.current.show(missingPermissions, 'video calls');
        }
      } catch (err) {
        console.log(err);
      }
    },
    hide() {
      baseModalRef.current.hide();
    },
  }));

  const startVideoCall = async () => {
    try {
      setCallStatus(CALL_STATUS.LOADING);
      dispatch(initVideoCall({ influencerId: influencer.id, secretModeId: secretMode.id }))
        .unwrap()
        .then(conversationUrl => {
          setConversationUrl(conversationUrl);
        })
        .catch(err => {
          notifyError(err.message);
          setCallStatus(CALL_STATUS.ENDED);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    switch (callStatus) {
      case CALL_STATUS.ACTIVE: {
        if (isAndroid) {
          setShouldDisplayAndroidConfirmation(true);
        }
        setTimeout(() => setShowNoteMessage(false), 35000);
        break;
      }
      case CALL_STATUS.ENDED: {
        setConversationUrl(null);
        setTimeout(() => {
          setCallStatus(CALL_STATUS.INACTIVE);
          setIsAndroid(false);
          setShouldDisplayAndroidConfirmation(false);
          setShowNoteMessage(true);
          onClose();
        }, 1200);
        break;
      }
      case CALL_STATUS.CANCEL: {
        setConversationUrl(null);
        setCallStatus(CALL_STATUS.INACTIVE);
        setIsAndroid(false);
        setShouldDisplayAndroidConfirmation(false);
        setShowNoteMessage(true);
        onClose();
        break;
      }
      default: {
        break;
      }
    }
  }, [callStatus, onClose, isAndroid, dispatch]);

  useEffect(() => {
    if (dailyError) {
      notifyError('Unable to join the call');
      setCallStatus(CALL_STATUS.CANCEL);
    }
  }, [dailyError]);

  const renderCallStatus = () => {
    switch (callStatus) {
      case CALL_STATUS.INACTIVE:
        return '';
      case CALL_STATUS.LOADING:
        return 'Connecting...';
      case CALL_STATUS.ACTIVE:
        if (dailyParticipantIds.length) {
          if (callDuration > 0) {
            return formatDuration(callDuration);
          }
          return 'Loading...';
        } else {
          return 'Loading...';
        }
      case CALL_STATUS.ENDED:
        return 'Call ended';
      default:
        return '';
    }
  };

  const InfluencerCameraComp = useMemo(() => {
    if ([CALL_STATUS.INACTIVE, CALL_STATUS.ENDED, CALL_STATUS.CANCEL].includes(callStatus)) {
      return null;
    }
    if (!dailyLocalSessionId || !dailyParticipantIds.length) {
      return (
        <LottieContainer>
          <Lottie animationData={logoAnimation} loop={true} />
        </LottieContainer>
      );
    }
    return (
      <Fragment key={dailyParticipantIds[0]}>
        <Camera sessionId={dailyParticipantIds[0]} fit="cover" />
        <DailyAudioTrack sessionId={dailyParticipantIds[0]} onPlayFailed={e => notifyInvisible('Press start')} />
      </Fragment>
    );
  }, [dailyLocalSessionId, dailyParticipantIds, callStatus]);

  const UserCameraComp = useMemo(() => {
    if (!dailyLocalSessionId) return null;
    return <Camera sessionId={dailyLocalSessionId} fit="cover" mirror={true} />;
  }, [dailyLocalSessionId]);

  return (
    <>
      <BaseCallModal
        ref={baseModalRef}
        onClick={() => {
          if (shouldDisplayAndroidConfirmation) {
            setShouldDisplayAndroidConfirmation(false);
          }
        }}>
        <Background />
        <BackgroundImage src={Stars} />
        <Header>
          <HeaderLeftBox>
            <InfluencerPhoto src={influencer?.profilePhoto} />
            <HeaderInfoBox>
              <HeaderInfluencerInfo>
                <Text14Bold>{influencer.name}</Text14Bold>
                {influencer.isVerified && <VerifiedIcon />}
              </HeaderInfluencerInfo>
              <Text12Light style={{ opacity: 0.6 }}>OnlyChats Video</Text12Light>
            </HeaderInfoBox>
          </HeaderLeftBox>
          <StatusText>{renderCallStatus()}</StatusText>
        </Header>
        <InfluencerCameraContainer>
          {isAndroid && shouldDisplayAndroidConfirmation && dailyParticipantIds.length ? (
            <Fragment key={dailyParticipantIds[0]}>
              <Camera sessionId={dailyParticipantIds[0]} fit="cover" />
              <DailyAudioTrack sessionId={dailyParticipantIds[0]} onPlayFailed={e => notifyInvisible('Press start')} />
            </Fragment>
          ) : (
            InfluencerCameraComp
          )}
          {callStatus === CALL_STATUS.LOADING ||
          (callStatus === CALL_STATUS.ACTIVE && (callDuration <= 0 || showNoteMessage)) ? (
            <InfluencerCameraContainerNoteContainer>
              <InfluencerCameraContainerNoteText>
                Wait a few seconds for the call to start...
              </InfluencerCameraContainerNoteText>
            </InfluencerCameraContainerNoteContainer>
          ) : null}
        </InfluencerCameraContainer>
        {callStatus !== CALL_STATUS.ENDED && (
          <ButtonContainer>
            <EndCallButton onClick={() => setCallStatus(CALL_STATUS.ENDED)}>
              <CloseIcon color="#fff" />
            </EndCallButton>
            <ButtonText>End</ButtonText>
          </ButtonContainer>
        )}
        {shouldDisplayAndroidConfirmation && callStatus !== CALL_STATUS.ENDED && (
          <EnableSoundWrapper>
            <EnableSoundComp onClick={() => setShouldDisplayAndroidConfirmation(false)} />
          </EnableSoundWrapper>
        )}
        <UserCameraContainer>{UserCameraComp}</UserCameraContainer>
      </BaseCallModal>
      <PermissionsModal
        ref={permissionsModalRef}
        onSuccess={() => {
          permissionsModalRef.current.hide();
          startVideoCall();
        }}
        onClose={() => {
          permissionsModalRef.current.hide();
          setCallStatus(CALL_STATUS.CANCEL);
        }}
      />
    </>
  );
});

export default VideoCallModal;
