import React from 'react';
import { Container, Photo, NameText } from './SecretModeListItem.styles';

function SecretModeListItem({ symbol, name, isActive, onClick }) {
  return (
    <Container onClick={onClick} $isActive={isActive}>
      <Photo>{symbol}</Photo>
      <NameText>{name}</NameText>
    </Container>
  );
}

export default SecretModeListItem;
