import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AuthLayout, PrivateLayout } from '../../layouts';
import {
  PageNotFound,
  InfluencersListPage,
  InfluencerProfilePage,
  UnauthenticatedPage,
  PrivacyPolicyPage,
  PersonalitiesPage,
  AppearancesPage,
  ProfilePage,
  SupportPage,
  FAQsPage,
  TermsAndConditionsPage,
  SplashPage,
} from '../../pages';
import GlobalStyles from '../../styles/globalStyles';
import '../../styles/fonts.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../../store/slices/user/asyncThunks';
import { selectLoggedIn, setLoggedIn } from '../../store/slices/auth/slice';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { DailyProvider, useCallObject } from '@daily-co/daily-react';
const TelegramWebApp = window.Telegram.WebApp;

function App() {
  const isLoggedIn = useSelector(selectLoggedIn);
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const callObject = useCallObject();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    TelegramWebApp.ready();
    TelegramWebApp.expand();
    TelegramWebApp.setHeaderColor('#000000');
    TelegramWebApp.disableVerticalSwipes();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const token = TelegramWebApp.initData || 'TOKEN_TEST';
    if (token) {
      dispatch(fetchUserInfo())
        .unwrap()
        .then(() => {
          let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
          if (startParam) {
            const influencerUsername = startParam.split('nav-')[1];
            navigate(`/influencers/${influencerUsername}`);
          }
        })
        .catch(err => {});
    } else {
      dispatch(setLoggedIn({ loggedIn: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <DailyProvider callObject={callObject}>
      <GlobalStyles />
      <ToastContainer newestOnTop={true} autoClose={3500} />
      {minimumDurationPassed && isLoggedIn !== null ? (
        <Routes>
          <Route element={<PrivateLayout />}>
            <Route path="/" element={<InfluencersListPage />} />
            <Route path="/hashtag/:appearanceName" element={<AppearancesPage />} />
            <Route path="/personality/:personalityName" element={<PersonalitiesPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/influencers/:influencerUsername" element={<InfluencerProfilePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<UnauthenticatedPage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <SplashPage />
      )}
    </DailyProvider>
  );
}

export default App;
