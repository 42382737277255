import styled from 'styled-components';
import { Text14Light } from '../../utils/texts/text.styles';

export const InputContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #432181;
  background: linear-gradient(90deg, #1d0f37 0%, #05020b 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  width: 100%;
  padding-inline: 1.6rem;
`;

export const NameText = styled(Text14Light)`
  color: #d0b3ff;
  opacity: 0.56;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1.2rem;
`;
