import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Content, ContentContainer, TagsContainer } from './AppearancesPage.styles';
import { selectFetchInfluencersByTagsInfo } from '../../store/slices/influencer/slice';
import { fetchInfluencersByTags } from '../../store/slices/influencer/asyncThunks';
import AppearanceTags from '../../components/AppearanceTags/AppearanceTags';
import PersonalitiesPageSkeleton from '../../components/skeleton/PersonalitiesPageSkeleton/PersonalitiesPageSkeleton';
import InfluencerCard from '../../components/InfluencerCard/InfluencerCard';
import Header from '../../components/Header/Header';

const AppearancesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appearanceName } = useParams();
  const { state } = useLocation();
  const initTag = state?.initTag;

  const { pending, data } = useSelector(selectFetchInfluencersByTagsInfo);

  const onAppearanceTagClick = value => {
    navigate(`/hashtag/${value}`);
  };

  useEffect(() => {
    if (appearanceName) {
      dispatch(fetchInfluencersByTags({ appearanceTags: [appearanceName], personalityTags: [] }));
    }
  }, [appearanceName, dispatch]);

  const onBack = () => {
    navigate(`/`);
  };

  return (
    <Container>
      <Header onBack={onBack} pageId="appearancesPage" />
      <ContentContainer>
        <TagsContainer>
          <AppearanceTags hideTitle onTagClick={onAppearanceTagClick} initTag={initTag} selectedTag={appearanceName} />
        </TagsContainer>
        {pending ? (
          <Content>
            <PersonalitiesPageSkeleton />
          </Content>
        ) : (
          <Content>
            {data.map(influencer => {
              return (
                <InfluencerCard
                  key={influencer.id}
                  name={influencer.name}
                  isVerified={influencer.isVerified}
                  image={influencer.image}
                  username={influencer.username}
                  age={influencer.age}
                  aboutMe={influencer.aboutMe}
                  aspectRatio={0.48}
                  category={influencer.category}
                  callOptions={influencer.callOptions}
                />
              );
            })}
          </Content>
        )}
      </ContentContainer>
    </Container>
  );
};

export default AppearancesPage;
