import styled from 'styled-components';
import { Text14Light } from '../../utils/texts/text.styles';
import EnableSound from '../../helper/EnableSound/EnableSound';

export const CoverPhotoContainer = styled.div`
  position: absolute;
  inset: 0;
`;

export const CoverPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CoverPhotoContainerShadowLayer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background: #05020a;
  opacity: 0.8;
`;

export const ProfilePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  z-index: 33;
  margin-top: 8rem;
`;

export const AvatarContainer = styled.div`
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  z-index: 1;
  background-color: #c4a0ff;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: auto;
  margin-bottom: 2.2rem;
`;

export const EndCallButton = styled.button`
  border-radius: 2.4rem;
  border: 1px solid #87171e;
  background: #87171e;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
  cursor: pointer;
`;

export const ButtonText = styled(Text14Light)`
  letter-spacing: -0.4px;
  user-select: none;
`;

export const EnableSoundWrapper = styled.div`
  z-index: 100;
  position: absolute;
  inset: 0;
  background-color: #00000070;
  width: 100%;
  height: 100%;
`;

export const EnableSoundComp = styled(EnableSound)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
