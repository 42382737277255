import styled from 'styled-components';
import { Text16Regular } from '../utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Title = styled(Text16Regular)`
  padding-left: 1.6rem;
`;

export const MobileContent = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  gap: 0.8rem;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  border-radius: 10px;
  border: ${({ $selected }) => ($selected ? '1px solid #B485FF' : '1px solid #2c1655')};
  background: ${({ $selected }) =>
    $selected
      ? 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #3e2072'
      : 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36'};
  padding: 0.8rem 3rem;
  cursor: pointer;
  margin-left: ${({ $first }) => $first && '1.6rem'};
  margin-right: ${({ $last }) => $last && '1.6rem'};

  p {
    white-space: nowrap;
  }
`;

export const HashtagText = styled(Text16Regular)`
  color: #d6c2ff;
`;

export const EmptyDiv = styled.div`
  min-width: 0.1px;
`;
