import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const PictureWrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  aspect-ratio: ${props => props.$aspectRatio};
  background: linear-gradient(#231346, #5a34ac);
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const SkeletonContainer = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;
