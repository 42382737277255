import React, { useState } from 'react';
import {
  AmountContainer,
  Container,
  Content,
  Label,
  Line,
  SectionContainer,
  SubscriptionCard,
  SubscriptionCardText,
  SubscriptionCardTextContainer,
} from './ProfilePage.styles';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { useNavigate } from 'react-router-dom';
import YourBalanceCard from '../../components/profile/YourBalanceCard/YourBalanceCard';
import SingleFieldInput from '../../components/profile/SingleFieldInput/SingleFieldInput';
import { Text14Light, Text16Bold, Text18Regular } from '../../components/utils/texts/text.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import SettingsButton from '../../components/profile/SettingsButton/SettingsButton';
import Header from '../../components/Header/Header';
import TelegramSupportModal from '../../components/modals/TelegramSupportModal/TelegramSupportModal';
import { capitalizeFirstLetter, getFormattedDate, isSubscribed } from '../../utils/util';
import { SUBSCRIPTION_CREDITS } from '../../utils/constants';
import RedirectToWebsiteModal from '../../components/modals/RedirectToWebsiteModal/RedirectToWebsiteModal';

export const GENDERS_LABEL = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

const ProfilePage = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [showRedirectToWebAppModal, setShowRedirectToWebAppModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const defaultPaymentMethod = user?.paymentMethods?.find(method => method.isDefault);

  return (
    <Container>
      <Header pageId="profilePage" />
      <Content>
        <YourBalanceCard
          onAddBalanceClick={() => {
            setEditMode(false);
            setShowRedirectToWebAppModal(true);
          }}
        />
        {isSubscribed(user.subscribedUntil) && (
          <div>
            <Label>Subscription</Label>
            <SubscriptionCard>
              <Text18Regular>{user.activeSubscription.plan}</Text18Regular>
              <AmountContainer>
                <Text16Bold>
                  ${user.activeSubscription.paidAmount} /{' '}
                  {user.activeSubscription.period.replace('_', ' ').toLowerCase()}
                </Text16Bold>
                <Text16Bold>{SUBSCRIPTION_CREDITS[user.activeSubscription.plan].TOTAL} 💎 / monthly</Text16Bold>
              </AmountContainer>
              <Line />
              <SubscriptionCardTextContainer>
                <SubscriptionCardText>Card details:</SubscriptionCardText>
                <Text14Light>
                  {capitalizeFirstLetter(defaultPaymentMethod.details.brand)} **** {defaultPaymentMethod.details.last4}
                </Text14Light>
              </SubscriptionCardTextContainer>
              <SubscriptionCardTextContainer>
                <SubscriptionCardText>
                  {user.activeSubscription.canceledAt ? 'Expires on:' : 'Renews on:'}
                </SubscriptionCardText>
                <Text14Light>{getFormattedDate(new Date(user.subscribedUntil))}</Text14Light>
              </SubscriptionCardTextContainer>
            </SubscriptionCard>
          </div>
        )}
        <SectionContainer>
          <Text14Light>Profile Info</Text14Light>
          <SingleFieldInput name="Email" value={user.email} />
          <SingleFieldInput
            name="Nickname"
            value={user.name}
            onEditClick={() => {
              setEditMode(true);
              setShowRedirectToWebAppModal(true);
            }}
          />
          <SingleFieldInput
            name="Gender"
            value={GENDERS_LABEL[user.gender]}
            onEditClick={() => {
              setEditMode(true);
              setShowRedirectToWebAppModal(true);
            }}
          />
        </SectionContainer>
        <SectionContainer>
          <Text14Light>Settings</Text14Light>
          <SettingsButton title="Support" icon={<HelpIcon />} onClick={() => setShowTelegramModal(true)} />
          <SettingsButton title="Privacy Policy" onClick={() => navigate(`/privacy-policy`)} />
          <SettingsButton title="Terms & Conditions" onClick={() => navigate(`/terms-and-conditions`)} />
        </SectionContainer>
      </Content>
      <TelegramSupportModal setShow={setShowTelegramModal} show={showTelegramModal} />
      <RedirectToWebsiteModal
        show={showRedirectToWebAppModal}
        setShow={setShowRedirectToWebAppModal}
        editMode={editMode}
      />
    </Container>
  );
};

export default ProfilePage;
