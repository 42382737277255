import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Container } from './AuthLayout.styles';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';

const AuthLayout = () => {
  const isLoggedIn = useSelector(selectLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default AuthLayout;
