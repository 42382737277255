import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactModal from 'react-modal';
import {
  Button,
  ButtonContainer,
  Content,
  Header,
  IconsContainer,
  ModalStyle,
  OverlayStyle,
  Text,
} from './PermissionsModal.styles';
import { Text22Bold } from '../../utils/texts/text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as MicrophoneIcon } from '../../../assets/icons/microphone.svg';
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera-permission.svg';
import { notifyError } from '../../../utils/notify';
import { capitalizeFirstLetter } from '../../../utils/util';

const EnablePermissionsModal = forwardRef(({ onSuccess, onClose }, ref) => {
  const [show, setShow] = useState(false);
  const [missingPermissions, setMissingPermissions] = useState([]);
  const [title, setTitle] = useState('');
  const [mainText, setMainText] = useState('');

  useImperativeHandle(ref, () => ({
    show(permissions, sufix) {
      setShow(true);
      setMissingPermissions(permissions);
      if (permissions.length === 2) {
        setTitle('Video Access');
        setMainText(`Enable your microphone and camera to initiate ${sufix}.`);
      } else if (permissions.length === 1) {
        setTitle(`${capitalizeFirstLetter(permissions[0])} Access`);
        setMainText(`Enable your ${permissions[0]} to initiate ${sufix}.`);
      }
    },
    hide() {
      setShow(false);
      setMissingPermissions([]);
      setTitle('');
      setMainText('');
    },
  }));

  const requestPermissions = async () => {
    try {
      const permissions = {
        ...(missingPermissions.includes('camera') && { video: true }),
        ...(missingPermissions.includes('microphone') && { audio: true }),
      };
      const stream = await navigator.mediaDevices.getUserMedia(permissions);
      if (permissions.audio) {
        stream.getTracks().forEach(track => track.stop());
      }
      onSuccess();
    } catch (error) {
      notifyError('It looks like you previously denied access. To enable it again, you must do it manually');
      onClose();
    }
  };

  const renderIcons = () => {
    if (!missingPermissions.length) return;
    if (missingPermissions.length === 2) {
      return (
        <IconsContainer>
          <MicrophoneIcon />
          <p>+</p>
          <CameraIcon />
        </IconsContainer>
      );
    } else if (missingPermissions.length === 1) {
      if (missingPermissions[0] === 'camera') {
        return <CameraIcon />;
      } else {
        return <MicrophoneIcon />;
      }
    }
  };

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => onClose()}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text22Bold>{title}</Text22Bold>
            <CloseIcon onClick={() => onClose()} />
          </Header>
          <Content>
            {renderIcons()}
            <Text>{mainText}</Text>
          </Content>
          <ButtonContainer>
            <Button title={'Allow access'} onClick={requestPermissions} />
          </ButtonContainer>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
});

export default EnablePermissionsModal;
