import React from 'react';
import {
  AllModelsSection,
  AllModelsTitleContainer,
  Container,
  Grid,
  TagsContainer,
  InfluencerCard,
} from './HomePageSkeleton.styles';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import { ReactComponent as TrendingIcon } from '../../../assets/icons/PersonalityTags/trending.svg';
import PersonalityTags from '../../PersonalityTags/PersonalityTags';
import AppearanceTags from '../../AppearanceTags/AppearanceTags';
import { Text18Bold } from '../../utils/texts/text.styles';

const HomePageSkeleton = () => {
  return (
    <Container>
      <TagsContainer>
        <PersonalityTags />
      </TagsContainer>
      <AllModelsSection>
        <AllModelsTitleContainer>
          <Text18Bold>Explore</Text18Bold>
          <TrendingIcon width={20} height={20} />
        </AllModelsTitleContainer>
        <Grid>
          {Array.from({ length: 8 }).map((_, index) => (
            <InfluencerCard $animationTime={6} key={index}>
              <LogoIcon />
            </InfluencerCard>
          ))}
        </Grid>
        <TagsContainer>
          <AppearanceTags />
        </TagsContainer>
        <Grid>
          {Array.from({ length: 8 }).map((_, index) => (
            <InfluencerCard $animationTime={6} key={index}>
              <LogoIcon />
            </InfluencerCard>
          ))}
        </Grid>
      </AllModelsSection>
    </Container>
  );
};

export default HomePageSkeleton;
