import styled from 'styled-components';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron_left.svg';

export const Container = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.6rem;
  height: 4rem;
`;

export const Name = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.42px;
  text-align: center;
  max-width: calc(100% - 4rem);
`;

export const Icon = styled(ChevronLeft)`
  rotate: 180deg;
  margin-left: 0.6rem;
`;
