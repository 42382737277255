import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 60rem;
  min-height: 100%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: #000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
