import styled from 'styled-components';
import { Text12Light } from '../utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    gap: 1rem;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.4rem;
  padding-left: ${({ $first }) => ($first ? '1.6rem' : '0')};
  padding-right: ${({ $last }) => ($last ? '1.6rem' : '0')};
`;

export const CircleTag = styled.div`
  border-radius: 22px;
  border: ${({ $selected }) => ($selected ? '1px solid #B485FF' : '1.375px solid #2c1655')};
  width: 4.4rem;
  height: 4.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $selected }) =>
    $selected
      ? 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #3e2072'
      : 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36'};
`;

export const TagText = styled(Text12Light)`
  line-height: 2.2rem;
`;
